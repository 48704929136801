import logo from './logo.svg';
import './App.css';

function App() {
  let path = window.location.href.split("/");
  let base = "https://commerce-ims.com";
  if(path[0].includes("localhost") || path[2].includes("localhost")) base = "http://localhost:3000"
  let last = path[path.length - 1]
  let id = last.includes("?") ? last.substring(0, last.indexOf("?")) : last
  let warehouse = null;
  let screen = 0;
  switch(id) {
      case "b8:27:eb:0e:ea:b4":
      case "b8:27:eb:eb:e5:92":
          screen = 1;
          warehouse = "Kentucky";
          break;
      case "b8:27:eb:79:54:ce":
      case "b8:27:eb:88:7c:63":
          screen = 2;
          warehouse = "Kentucky";
          break;
      case "b8:27:eb:88:9f:a2":
      case "b8:27:eb:89:52:9c":
          screen = 3;
          warehouse = "Kentucky";
          break;
      case "b8:27:eb:87:51:d0":
      case "b8:27:eb:63:0c:07":
          screen = 4;
          warehouse = "Kentucky";
          break;
      case "b8:27:eb:7e:e5:a0":
          screen = 1;
          warehouse = "Pennsylvania";
          break;
      case "b8:27:eb:67:91:d6":
          screen = 2;
          warehouse = "Pennsylvania";
          break;
  }
  window.location.href = `${base}/shipping_dash/fullscreen/${warehouse}/screen/${screen}`

  
  return (
    <div className="App">
      
    </div>
  );
}

export default App;
